
    $(document).ready(function(){

		/*	Toggle navigation
		-------------------------------------------------------------------------- */
		
		$('.navbar-toggle').click(function() {
			var menu = $('.navbar');
			if (menu.hasClass('is-active')) {
				$('.navbar-toggle').removeClass('is-active');
			   	 	menu.removeClass('is-active');
 					$('.overlay').remove();
			}
			else {
				$('.navbar-toggle').addClass("is-active");
			    	menu.addClass('is-active');
				    $('body').append($('<div/>', {
				        class: 'overlay' 
				    }));
			}
			return false;
		});

		$('.primary-nav a').on('click', function(){
		    $('.navbar-toggle').click();
		});

		/*	Smooth scroll
		-------------------------------------------------------------------------- */

		$('a[href^="#"]').on('click',function (e) {
		    e.preventDefault();

		    var target = this.hash;
		    var $target = $(target);

		    $('html, body').stop().animate({
		        'scrollTop': $target.offset().top
		    }, 900, 'swing');
		});

		/*	Accordion
		-------------------------------------------------------------------------- */

		$('.accordion').find("div:first").removeClass("is-closed");
	    $('.accordion__heading').click(function () {
	        $(this).next('.accordion__text').slideToggle(200);
	        $(this).parent().toggleClass("is-closed");
	        //$(this).parent().siblings().addClass("is-closed").children().next().slideUp(200);
	        return false;
	    });

		/*	sticky header
		-------------------------------------------------------------------------- */

	    var window_width = jQuery( window ).width();

	    if (window_width < 768) {
	      jQuery(".page-header").trigger("sticky_kit:detach");
	    } else {
	      make_sticky_header();
	    }

	    jQuery( window ).resize(function() {

	      window_width = jQuery( window ).width();

	      if (window_width < 768) {
	        jQuery(".page-header").trigger("sticky_kit:detach");
	      } else {
	        make_sticky_header();
	      }

	    });

	    function make_sticky_header() {
			$(".page-header").stick_in_parent({
				sticky_class: "is-sticky"
			});
	    }

		/*	sticky sidebar
		-------------------------------------------------------------------------- */

	    var window_width = jQuery( window ).width();

	    if (window_width < 768) {
	      jQuery(".sidebar").trigger("sticky_kit:detach");
	    } else {
	      make_sticky_sidebar();
	    }

	    jQuery( window ).resize(function() {

	      window_width = jQuery( window ).width();

	      if (window_width < 768) {
	        jQuery(".sidebar").trigger("sticky_kit:detach");
	      } else {
	        make_sticky_sidebar();
	      }

	    });

	    function make_sticky_sidebar() {
			$(".sidebar").stick_in_parent({
				sticky_class: "is-sticky",
				offset_top: 100,
			});
	    }


		/*	scrollReveal
		-------------------------------------------------------------------------- */

		window.sr = ScrollReveal({
			reset: false,
			mobile: false
		});
		sr.reveal( 'h1', 
			{ 
				easing   : 'linear',
				opacity	 : 0,
				origin	 : 'top',
				distance : '10vw',
				duration : 500, 
				delay    : 100
			}
		);
		sr.reveal( '.box__heading',
			{
				easing   : 'ease-in-out',
				scale	 : 2,
				delay    : 200,
			} 
		);

	});

	// hide menu if click outside

	$(document).on('click', function(event) {
		if (!$(event.target).closest('.navbar').length) {
			$('.navbar-toggle').removeClass("is-active");
			$('.navbar').removeClass("is-active");
			$('.overlay').remove();
		}
	});

	$(document).on('touchstart', function(event) {
		if (!$(event.target).closest('.navbar').length) {
			$('.navbar-toggle').removeClass("is-active");
			$('.navbar').removeClass("is-active");
			$('.overlay').remove();
		}
	});